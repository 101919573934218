"use client";

import { ReactNode, useMemo } from "react";
import { useCart } from "~/hooks/useCart";
import { cn } from "~/lib/utils";
import { motion } from "framer-motion";

export default function NavigationCartAmount(): ReactNode {
  const { uiItems, giftcards, seatUpgrades } = useCart();

  const quantity = useMemo(
    () =>
      uiItems.reduce((acc, item) => acc + item.quantity, 0) +
      giftcards.length +
      seatUpgrades.reduce((sum, upgrade) => sum + upgrade.lineItems.length, 0),
    [uiItems, giftcards, seatUpgrades],
  );

  if (!quantity) return null;

  return (
    <motion.span
      animate={{ scale: [1, 1.1, 1] }}
      transition={{ duration: 0.25, type: "keyframes" }}
      key={quantity}
      className={cn(
        "leading-xs absolute -right-4 -top-4 flex h-5 flex-row items-center justify-center rounded-full bg-black text-xs font-bold text-white",
        quantity > 9 ? "w-[1.7rem]" : "w-5",
      )}
    >
      {quantity}
    </motion.span>
  );
}
