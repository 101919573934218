"use client";

import { JSX, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import Image from "next/image";
import { alreadySomeFanticketUpgrades, cn, moneyToCurrency } from "~/lib/utils";
import { Button } from "~/components/ui/button";
import { useTranslations } from "~/hooks/useTranslations";
import { useCart } from "~/hooks/useCart";
import showdown from "showdown";
import { useAddOnAvailable } from "~/app/cart/_components/AddOns/useAddOnAvailable";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FALLBACK_IMAGE } from "~/app/cart/_components/AddOns/constants";
import { useShopConfig } from "~/hooks/useShopConfig";
import { useLocale } from "~/hooks/useLocale";
import { useMediaQuery } from "usehooks-ts";

interface IAddOnContent {
  onAddFanticket?: () => void;
}

export function AddOnContent({
  onAddFanticket,
}: Readonly<IAddOnContent>): JSX.Element {
  const t = useTranslations();
  const {
    shopConfig: { fanTicket },
  } = useShopConfig();

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const locale = useLocale();

  const { addFanticket, items } = useCart();

  const addOnAvailable = useAddOnAvailable();

  const alreadyItemsUpgraded = useMemo(
    () => alreadySomeFanticketUpgrades(items),
    [items],
  );

  const description = useMemo(() => {
    const defaultLanguage = locale ?? fanTicket?.description?.defaultLanguage;

    return new showdown.Converter().makeHtml(
      fanTicket?.description?.translations?.[defaultLanguage] ??
        t.additional_options.your_fantickets,
    );
  }, [fanTicket, locale, t.additional_options.your_fantickets]);

  const thumbnail = useMemo(() => {
    return fanTicket?.imageVariants.reduce((prev, current) =>
      Number(prev.dimensions?.width) > Number(current.dimensions?.width)
        ? prev
        : current,
    );
  }, [fanTicket]);

  const onAdd = () => {
    addFanticket(fanTicket);
    onAddFanticket?.();
  };

  return addOnAvailable ? (
    <>
      <h1
        className={cn(
          "mt-4 text-2xl font-bold leading-2xl",
          !isDesktop && "px-4 py-2",
        )}
      >
        {t.additional_options.title}
      </h1>
      <div>
        <div
          style={{
            backgroundColor: fanTicket?.headerBg ?? "#FFCC00",
            color: fanTicket?.headerFg ?? "#000",
          }}
          className={cn(
            "flex gap-2 p-4 align-middle",
            isDesktop && "rounded-t-[10px]",
          )}
        >
          <div>
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div>{t.additional_options.your_fantickets}</div>
        </div>
        <div
          className={cn(
            "flex gap-4 rounded-b-[10px] bg-[#fff] p-4 align-middle",
            !isDesktop && "flex-col items-center",
          )}
        >
          <Image
            width={thumbnail?.dimensions?.width}
            height={thumbnail?.dimensions?.height}
            alt={thumbnail?.url ?? ""}
            src={thumbnail?.url ?? FALLBACK_IMAGE}
            className={cn(
              "h-full w-full max-w-96 rounded-md object-cover object-center md:flex",
              !isDesktop && "max-w-[500px]",
            )}
          />
          <div className={cn("w-[100%]", !isDesktop && "text-sm")}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <div className="mt-[30px] flex items-center gap-[8px]">
              <div className="text-xl font-bold text-black">
                {fanTicket?.price && moneyToCurrency(fanTicket.price)}
              </div>
              <div className="text-black">
                {t.additional_options.per_ticket}
                {fanTicket?.allowSingleTicket && "*"}
              </div>
            </div>
            <div>
              {fanTicket?.allowSingleTicket &&
                t.additional_options.whole_order_only}
            </div>
            <div className="mt-[15px] w-[100%] text-right font-bold">
              <Button
                variant="default"
                type="button"
                className={cn(
                  "font-bold",
                  alreadyItemsUpgraded && "bg-green-500",
                )}
                disabled={alreadyItemsUpgraded}
                onClick={onAdd}
              >
                {alreadyItemsUpgraded ? (
                  <div>
                    <FontAwesomeIcon icon={faCheck} />
                    {t.additional_options.already_added}
                  </div>
                ) : (
                  t.additional_options.add
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}
